.card {
  width: 75% !important;
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 15px;
}
.mtb-7{
  margin-top: 5rem !important; 
  margin-bottom: 5rem !important; 
}
.adiv {
  background: #f7f5ed;
  color: #4d100a !important;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
  height: 46px;
}

span {
  font-size: 0.45cm !important;
}

.fas {
  cursor: pointer;
}

.fa-chevron-left {
  color: #fff;
}

.form-title {
    font-size: 120% !important;
    font-weight: bold;
    margin-bottom: 25px !important;
}
.form-sub-title strong{
  font-family: 'Cairo';
  color: #4d100a;
}
.form-sub-title samp{
  font-family: 'Cairo';
}
@keyframes animated-cursor{
  from{border-bottom-color: #4d100a;}
to{border-bottom-color: transparent;}
}

/*.form-title::before {
    content: "";
    width: 60px !important;
    height: 4px !important;
    background-color: #4d100a !important;
    position:absolute !important;
    border-radius: 70px;
    top: 19% !important;
    right: 26% !important;
   animation: animated-cursor 600ms steps(30,end) infinite;
  }*/
  
  /*.form-title::after {
    content: "";
    width: 60px !important;
    height: 4px !important;
    background-color: #4d100a !important;
    position:absolute !important;
    border-radius: 70px;
    top: 19% !important;
    left: 26%;
  }*/